'use client'
import Avatar from '@/components/avatar'
import Username from '@/components/username'
import UserContext from '@/context/user-context'
import { pingback } from '@giphy/js-analytics'
import { IChannel, IProfileUser } from '@giphy/js-types'
import { useContext } from 'react'
import { InView } from 'react-intersection-observer'

type Props = {
    channel: IChannel
    channelResponseId: string
}

const ChannelAvatar = ({ channel, channelResponseId }: Props) => {
    const { user } = useContext(UserContext)
    return (
        <InView
            as="span"
            key={channel.id}
            triggerOnce
            threshold={1}
            onChange={(inView) => {
                if (inView) {
                    pingback({
                        userId: user ? user.id : undefined,
                        actionType: 'SEEN',
                        attributes: {
                            search_response_id: channelResponseId,
                        },
                        analyticsResponsePayload: channel.analytics_response_payload,
                    })
                }
            }}
        >
            <a
                href={(channel?.user as IProfileUser).profile_url}
                onClick={(e) => {
                    e.preventDefault()
                    pingback({
                        userId: user ? user.id : undefined,
                        actionType: 'CLICK',
                        attributes: {
                            search_response_id: channelResponseId,
                        },
                        analyticsResponsePayload: channel.analytics_response_payload,
                    })
                    window.location.href = (channel?.user as IProfileUser).profile_url
                }}
                className="flex gap-2"
            >
                <Avatar user={channel.user} size={45} />
                <div>
                    <h4 className="whitespace-nowrap">{channel.user.display_name}</h4>
                    <Username user={channel.user} className="whitespace-nowrap" />
                </div>
            </a>
        </InView>
    )
}

export default ChannelAvatar
