'use client'
import FlagIcon from '@/app/icons/flag'
import Button from '@/components/button'
import { cn } from '@/util/cn'
import { Dispatch, ReactNode, SetStateAction, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import ReportSearch from '../report-search'

type Props = {
    label: string
    icon: ReactNode
    children: ReactNode[] | ReactNode
    isDisabled?: boolean
    setShow?: Dispatch<SetStateAction<boolean>>
    show?: boolean
}

const SearchFilter = ({ label, icon, children, isDisabled = false, show, setShow }: Props) => {
    const [showDropdown, setShowDropdown] = useState<Boolean>(false)
    // optionally hoist state
    const state = show !== undefined ? show : showDropdown
    const action = setShow !== undefined ? setShow : setShowDropdown
    const ref = useRef(null)
    useClickAway(ref, () => {
        setShowDropdown(false)
    })
    return (
        <div
            ref={ref}
            className={cn('relative flex h-10 w-24 flex-col', isDisabled && 'pointer-events-none opacity-50')}
        >
            <Button
                className="bg-giphyDarkestGrey flex cursor-pointer items-center gap-2 rounded-sm"
                onClick={() => action(!state)}
            >
                {icon}
                {label}
            </Button>
            {state && <>{children}</>}
        </div>
    )
}

export const ReportButton = ({ type, term, gifResponseId }: { type: string; term: string; gifResponseId: string }) => {
    const [showModal, setShowModal] = useState<boolean>(false)
    return (
        <SearchFilter
            label={'Report'}
            icon={<FlagIcon className="size-4 [--stop-color-1:--giphy-red] [--stop-color-2:--giphy-pink]" />}
            show={showModal}
            setShow={setShowModal}
        >
            <ReportSearch
                type={type as 'stickers' | 'gifs'}
                searchTerm={term}
                onClose={setShowModal}
                gifResponseId={gifResponseId!}
            />
        </SearchFilter>
    )
}

export default SearchFilter
