import CheckmarkIcon from '@/app/icons/checkmark'
import { cn } from '@/util/cn'
import { InputHTMLAttributes } from 'react'
import { omit } from 'utils'

const Checkbox = (props: InputHTMLAttributes<HTMLInputElement>) => {
    return (
        <div className="inline-flex items-center">
            <label className="relative flex cursor-pointer items-center">
                <input
                    type="checkbox"
                    className={cn(
                        `border-giphyLightCharcoal bg-giphyBlack checked:bg-giphyLightCharcoal peer m-0 size-5 cursor-pointer appearance-none rounded border-2 border-solid shadow transition-all hover:shadow-md`,
                        props.className
                    )}
                    {...omit(props, ['className'])}
                />
                <span className="pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform text-white opacity-0 peer-checked:opacity-100">
                    <CheckmarkIcon className="size-3" />
                </span>
            </label>
        </div>
    )
}

export default Checkbox
