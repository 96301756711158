'use client'
import { ReactNode, SetStateAction, createContext, useMemo, useState } from 'react'
type CarouselContextProps = {
    carouselIndex?: number
    setCarouselIndex: React.Dispatch<SetStateAction<number>>
}

export const SearchCarouselContext = createContext({
    carouselIndex: 0,
} as CarouselContextProps)

const CarouselContextManager = ({ children }: { children: ReactNode }) => {
    const [carouselIndex, setCarouselIndex] = useState(0)
    const value = useMemo(() => ({ carouselIndex, setCarouselIndex }), [carouselIndex])
    return <SearchCarouselContext.Provider value={value}>{children}</SearchCarouselContext.Provider>
}

export default CarouselContextManager
