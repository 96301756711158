import { SVGAttributes } from 'react'

const CheckmarkIcon = (props: SVGAttributes<SVGElement>) => (
    <svg fill={props.color || 'white'} viewBox="0 0 24 18" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.8507 3.92084L9.36521 17.8648C9.24811 17.9437 9.14272 18 9.0022 18C8.86167 18 8.75628 17.9437 8.63918 17.8648L0.149305 9.68767C-0.0497682 9.50745 -0.0497682 9.18082 0.149305 9.0006L3.18078 5.94301C3.36814 5.7628 3.70773 5.7628 3.8951 5.94301L9.0022 10.6915L20.0075 0.13516C20.1948 -0.0450533 20.5344 -0.0450533 20.7218 0.13516L23.8507 3.23378C24.0498 3.41399 24.0498 3.74063 23.8507 3.92084Z"
        />
    </svg>
)
export default CheckmarkIcon
