'use client'
import { CloseIcon } from '@/app/icons/navigation'
import Button from '@/components/button'
import Checkbox from '@/components/checkbox'
import { FlashMessageContext } from '@/context/flash-message'
import { cn } from '@/util/cn'
import { concat, without } from 'lodash'
import { ChangeEvent, SetStateAction, useContext, useState } from 'react'
import { useCookie } from 'react-use'
import { ReportSearchPost } from './api'
import { AdditionalInfoInput, ColorBar, Dim, Footer, Heading, Modal, ReasonsList, Term } from './style'

const SEARCH_INFO = 360019823451
const REPORT_GIF = 360020287371
const GUIDELINES = 360020286811
const DMCA = 360020287091

const REASONS_ORDER = [
    'NOT_RELEVANT',
    'NOT_FUN',
    'NOT_SAFE',
    'NOT_TIMELY',
    'NOT_ENOUGH_VERIFIED_CONTENT',
    'NOT_DIVERSE',
]

const REASONS_DESCRIPTION = {
    NOT_RELEVANT: 'Results are not relevant',
    NOT_FUN: 'Results are not very fun',
    NOT_SAFE: 'Results are unsafe',
    NOT_TIMELY: 'Results do not have the newest content',
    NOT_ENOUGH_VERIFIED_CONTENT: 'Results aren’t high quality',
    NOT_DIVERSE: 'Results are not diverse or lack representation',
}

const HelpCenterLink = ({ id, text }: { id: number; text: string }) => (
    <a href={`https://support.giphy.com/hc/en-us/articles/${id}`} target="_blank" rel="noreferrer">
        {text}
    </a>
)

type Props = {
    type: 'gifs' | 'stickers'
    gifResponseId: string
    searchTerm: string
    onClose: React.Dispatch<SetStateAction<boolean>>
}

const ReportSearch = ({ type, gifResponseId, searchTerm, onClose = () => {} }: Props) => {
    const { showMessage } = useContext(FlashMessageContext)
    const [selectedReasons, setSelectedReasons] = useState<string[]>([])
    const [selectedContentTypes, setSelectedContentTypes] = useState<string[]>([])
    const [additionalInfo, setAdditionalInfo] = useState('')
    const [csrf] = useCookie('csrftoken')
    const csrftoken = csrf || ''
    const submitClicked = async () => {
        let responseStatus
        for (const contentType of selectedContentTypes) {
            const responseId = gifResponseId
            responseStatus = await ReportSearchPost({
                reasons: selectedReasons.join(','),
                additionalInfo,
                responseId,
                searchTerm,
                contentType,
                csrftoken,
            })
        }
        showMessage({
            message: responseStatus ? `Successfully submitted report!` : `Error submitting report`,
            type: responseStatus ? 'success' : 'error',
        })
        onClose(false)
    }

    const closeClicked = () => {
        onClose(false)
    }
    return (
        <>
            <Dim onClick={closeClicked} />
            <Modal>
                <ColorBar />
                <CloseIcon className="absolute right-4 top-4 size-5 cursor-pointer" onClick={() => closeClicked()} />
                <Heading>
                    Why are you reporting the search for &quot;<Term>{searchTerm}</Term>&quot;?
                </Heading>
                <ReasonsList>
                    {REASONS_ORDER.map((key) => (
                        <div className="flex gap-2 py-1 text-sm font-bold" key={key}>
                            <Checkbox
                                checked={selectedReasons.includes(key)}
                                onChange={() =>
                                    setSelectedReasons((prev) =>
                                        selectedReasons.includes(key) ? without(prev, key) : concat(prev, key)
                                    )
                                }
                            />
                            {REASONS_DESCRIPTION[key as keyof typeof REASONS_DESCRIPTION]}
                        </div>
                    ))}
                </ReasonsList>
                <Heading>
                    What type of content are you reporting?
                    <span>Required</span>
                </Heading>
                <div className="mb-5 flex gap-4">
                    <div className="flex gap-2 py-1 text-sm font-bold">
                        <Checkbox
                            checked={selectedContentTypes.includes(type)}
                            onChange={() => {
                                setSelectedContentTypes((prev) =>
                                    selectedContentTypes.includes(type)
                                        ? without(prev, type)
                                        : [...selectedContentTypes, type]
                                )
                            }}
                        />
                        {type === 'stickers' ? 'Stickers' : 'GIFs'}
                    </div>
                    <div className="flex gap-2 py-1 text-sm font-bold">
                        <Checkbox
                            checked={selectedContentTypes.includes('clips')}
                            onChange={() => {
                                setSelectedContentTypes((prev) =>
                                    selectedContentTypes.includes('clips')
                                        ? without(prev, 'clips')
                                        : [...selectedContentTypes, 'clips']
                                )
                            }}
                        />
                        Clips
                    </div>
                </div>
                <p>
                    Learn how to find the results you want and how these reports are used{' '}
                    <HelpCenterLink id={SEARCH_INFO} text={'here'} />.
                </p>
                <br />
                <Heading>
                    Any additional information?
                    <span>Required</span>
                </Heading>
                <AdditionalInfoInput
                    value={additionalInfo}
                    onChange={(evt: ChangeEvent<HTMLTextAreaElement>) => setAdditionalInfo(evt.target.value)}
                />
                <>
                    <Button
                        onClick={submitClicked}
                        disabled={!selectedContentTypes.length || !additionalInfo}
                        className={cn(
                            'w-full',
                            selectedContentTypes.length && additionalInfo
                                ? 'bg-gradient-primary'
                                : 'bg-giphyDarkCharcoal'
                        )}
                    >
                        Submit
                    </Button>
                    <Button className="bg-giphyDarkGrey w-full" onClick={closeClicked}>
                        Cancel
                    </Button>
                </>
                <Footer>
                    <HelpCenterLink id={REPORT_GIF} text={'Report an Inappropriate GIF '} /> |{' '}
                    <HelpCenterLink id={DMCA} text={'DMCA Copyright Policy'} /> |
                    <HelpCenterLink id={GUIDELINES} text={'Community Guidelines '} />
                </Footer>
            </Modal>
        </>
    )
}

export default ReportSearch
